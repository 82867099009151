<template>
    <div class="fc">
    
      <div class="page-title fr">
        <div @click="backPage" class="back-icon"><el-icon><Back></Back></el-icon></div>
        产品管理</div>
      <st-table style="width:100%" ref="roleTable" :cols="tableCols" 
        :records="dataList.records"
        :current="dataList.current"
        :pages="dataList.pages"
        :total="dataList.total"
        @doSearch="doSearch"
        @pageChange="pageChange"
        @currentChange="rowChange"
        @newRow = "addRow" @editRow="editRow"
        >
        <el-tooltip 
              class="box-item"
              effect="dark"
              content="产品规格"
              placement="top-start"
            >
              <el-button circle @click="editSku"
                ><el-icon><Money/> </el-icon
              ></el-button>
            </el-tooltip>
            <el-tooltip 
              class="box-item"
              effect="dark"
              content="产品排期"
              placement="top-start"
            >
              <el-button circle @click="editSche"
                ><el-icon><Calendar/> </el-icon
              ></el-button>
            </el-tooltip>
      </st-table>
      <st-form :show="showDialog" @dialogClose="dialogClose" @saveForm ="saveForm" :formInfo="roleFormInfo" :formData="roleInfo" :rule="roleFormRole" ></st-form>
   </div>
  </template>
  <script src='./pack.js'></script>
  