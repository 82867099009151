
import * as pack from '../../api/pack.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';
import roleView from '../../assets/modelView/pack.json';
export default({
    name:"Pack",
    data(){
        return {
            shopId:null,
            roomId:null,
            tableCols:[],
            queryForm:{
                pageIdx:1,
                pageSize:10,
                str:'',
                shopId:null,
            },
            dataList:{
                records:[],
                total:0,
                current:1,
                pages:1
            },
            roleFormInfo:{},
            roleInfo:{
                id:null,
                roleName:null,
            },
            roleFormRole:{

            },
            showDialog:false,
            cur:{}
        }
    },
    async mounted(){
        this.shopId = this.$route.query.shopId;
        this.roomId = this.$route.query.roomId;
        this.queryForm.shopId =  this.$route.query.shopId;
        this.queryForm.roomId = this.$route.query.roomId;
        this.tableCols = roleView.tableCol;
        this.roleFormInfo = roleView.editForm;
        this.roleFormRole = formToRule(roleView.editForm);
        await this.queryRole();
    },
    methods:{
       async queryRole(){
            const {data} = await pack.getShopPackage(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList = data.data;
            }
       },
       async doSearch(str){
        this.queryForm.pageIdx = 1;
        this.queryForm.str = str;
        const {data} = await pack.getShopPackage(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList = data.data;
            }
       },
       async pageChange(idx,str){
        this.queryForm.pageIdx = idx;
        this.queryForm.str = str;
        const {data} = await pack.getShopPackage(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList = data.data;
            }
       },
       async editSche(){
        console.log(this.cur.id);
        if(this.cur.id!=null){
            if(this.cur.packType=="0"){
               this.$router.push("/bu/sche?packId="+this.cur.id)
                // console.log(data);
            }else{
                ElMessage({
                    message: '现场排队产品无需排期',
                    type: 'warning',
                  });
            }
        } else{
            ElMessage({
                message: '请先选择产品',
                type: 'warning',
              });
        }
       },
       editSku(){
        console.log(this.cur.id);
        if(this.cur.id!=null){
            this.$router.push("/bu/sku?packId="+this.cur.id)
        } else{
            ElMessage({
                message: '请先选择产品',
                type: 'warning',
              });
        }
       },
       addRow(){
        this.roleInfo = {};
        this.showDialog = true;
       },
       editRow(row){
        this.roleInfo = JSON.parse(JSON.stringify(row));
        this.showDialog = true;
       },
       async saveForm(row){
            var  that = this;
            row.shopId = this.shopId;
            row.roomId = this.roomId;
            const {data} = await pack.editShopPack(row);
            if(data.success){
                ElMessage({
                    message: '保存成功',
                    type: 'success',
                  });
                  that.$refs.roleTable.doSearch();
                  that.showDialog = false;
            }
            
        
          
       },
       dialogClose(){
        this.showDialog = false;
    },
    backPage(){
        this.$router.back();
    },
    rowChange(row){
        console.log(row);
        this.cur = JSON.parse(JSON.stringify(row));
    },
    }
})