import axios from "axios";
import http from "../utils/http";

var baseReqUrl = "coupon/"

//查询列表
export const getCpList = (data) => {
    return http({
        url: baseReqUrl + "list",
        method: "GET",
        data,
    })
}

export const getById = (data) => {
    return http({
        url: baseReqUrl + "getById",
        method: "GET",
        data,
    })
}


//编辑新建优惠券
export const editCp = (data) => {
    return http({
        url: baseReqUrl + "editCp",
        method: "POST",
        data,
    })
}