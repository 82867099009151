import axios from "axios";
import {ElLoading,ElMessage} from 'element-plus';
var instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL, //接口统一域名
    timeout: 60000, //设置超时
    headers: {
        'Content-Type': 'application/json;charset=UTF-8;',
    }
})
let loading;
//正在请求的数量
let requestCount = 0
//显示loading
const showLoading = () => {
  
    if (requestCount === 0 && !loading) {
        console.log("showloading.....")
        loading = ElLoading.service({
            text: "Loading  ",
            background: 'rgba(0, 0, 0, 0.7)',
            spinner: 'el-icon-loading',
        })
    }
    requestCount++;
}
//隐藏loading
const hideLoading = () => {
    requestCount--
    if (requestCount == 0) {
        loading.close()
        loading = null;
    }
}

//请求拦截器 
instance.interceptors.request.use((config) => {
    showLoading()
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    const token = window.sessionStorage.getItem('token');
    token && (config.headers.token = token);
    return config;
}, (error) =>
    // 对请求错误做些什么
    Promise.reject(error));

//响应拦截器
instance.interceptors.response.use((response) => {
    hideLoading()
    //响应成功
    const { status } = response;
    if(status === 200) {
        if (!response.data.success) {
            ElMessage.error(response.data.message);
        }
        return response;
    }else {
        ElMessage.error(response.data.message);
    }
}, (error) => {
    //响应错误
    if (error.response && error.response.status) {
        const status = error.response.status;
        let message = '';
        switch (status) {
            case 401:
                message = '没有权限';
                break;
            case 404:
                message = '请求地址出错';
                break;
            case 408:
                message = '请求超时';
                break;
            case 500:
                message = '服务器内部错误!';
                break;
            default:
                message = '请求失败'
        }
        ElMessage.error(message);
        hideLoading();
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

const http = (config) => {
    return instance({
        method: config.method ? config.method : 'GET',
        data: config.method === 'POST' ? config.data : null,
        params: config.method == 'GET' || !config.method ? config.data : null,
        url: config.url,
    })
}


export default http;