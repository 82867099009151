
import * as pack from '../../api/pack.js'
import * as room from '../../api/room.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';

export default({
    name:"Sc",
    data(){
        return {
            scheDate:'',
            shopId:'',
            shopList:[],
            packList:[],
            roomList:[],
        }
    },
    
    async mounted(){
        await this.getShopList();
      this.initDate();
      await this.shopDateSche();
    },
    methods:{

      async getRoomList(){
        const {data} = await room.query({
          pageSize:100,
          shopId:this.shopId
      });
        this.roomList = data.data.records;
      },
        async getShopList(){
            const {data} = await pack.shopList({
                pageSize:100,
                str:""
            });
            this.shopList = data.data.records;
            this.shopId = this.shopList[0].id;
        },
      initDate(){
        const currentDate = new Date();
        this.scheDate = this.getStringFromDate(currentDate);
      },
      async shopDateSche(){
        const {data} = await pack.shopDateSche({
            shopId:this.shopId,
            scheDate:this.scheDate
        });
        console.log(data.data)
        this.packList = data.data;
      },
      getStringFromDate(currentDate){
        const year = String(currentDate.getYear() + 1900); // 需要加上1900
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 需要补零
const day = String(currentDate.getDate()).padStart(2, '0'); // 需要补零
        return  year+"-"+month+"-"+day;
      },
      async setDate(v){
        var dt = new Date(this.scheDate);
        var day = dt.getDate();
        dt.setDate(day+v);
        this.scheDate = this.getStringFromDate(dt);
        await this.shopDateSche();
      },
      async inArea(row){
        let form = {
            id:row.id,
            scStatus:'1',
        }
        const {data} = await pack.setAreaState(form);
        if(data.success);
        await this.shopDateSche();
      },
      async ourArea(row){
        let form = {
            id:row.id,
            scStatus:'2',
        }
        const {data} = await pack.setAreaState(form);
        if(data.success);
        await this.shopDateSche();
      },
      async orderArea(row){
        const {data} = await pack.inSche(row);
        if(data.success)
        await this.shopDateSche();
      }
    }
})