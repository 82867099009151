<template>
    <div class="fc">
      <div class="page-title">支付订单查询</div>
      <st-table style="width:100%" ref="roleTable" :cols="tableCols" 
        :records="dataList.records"
        :current="dataList.current"
        :pages="dataList.pages"
        :total="dataList.total"
        @currentChange="rowChange"
        queryUrl="pack/queryOrders"
        :newButton="false"
        :editButton="true"
        @editRow="editRow"
        >
        <el-tooltip 
              class="box-item"
              effect="dark"
              content="退款"
              placement="top-start"
            >
              <el-button circle @click="refund"
                ><el-icon><Money/> </el-icon
              ></el-button>
            </el-tooltip>
            <el-tooltip 
              class="box-item"
              effect="dark"
              content="取消预约"
              placement="top-start"
            >
              <el-button circle @click="cancelOrder"
                ><el-icon><Delete/> </el-icon
              ></el-button>
            </el-tooltip>
      </st-table>
      <st-form :show="showDialog" @dialogClose="dialogClose" @saveForm ="saveForm" :formInfo="roleFormInfo" :formData="roleInfo" :rule="roleFormRole" ></st-form>
   
      <el-dialog v-model="refundDialog" :show-close="false" title="订单退款" v-loading="loading">
        <el-form ref="refundForm" size="large" :model="refundInfo" :rules="refundRole" label-width="100">
          <el-form-item label="退款金额" prop="refundAmount">
            <el-input-number
              v-model="refundInfo.refundAmount"
              :precision="2"
              :step="0.01"
              min="0"
              :max="curOrder.finalAmount"
            />
          </el-form-item>
          <el-form-item  label="退款时间" >
            {{ refundInfo.refundTime }}
          </el-form-item>
          <el-form-item  label="退款详情" >
            {{ refundInfo.refundInfo }}
          </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="refundDialog = false">关闭</el-button>
        <el-button type="primary" @click="startRefund"> 保存 </el-button>
      </span>
    </template>
      </el-dialog>
    </div>
  </template>
  <script src='./Order.js'></script>
  