<template>
  <!-- 查询行 -->
  <div class="fc" style="gap: 0">
    <div v-if="search" class="fr query-row">
      <el-input size="large" v-model="str" placeholder="请输入查询关键字">
        <template #append>
          <el-button @click="doSearch"
            ><el-icon><Search /></el-icon
          ></el-button>
        </template>
      </el-input>
    </div>
    <!-- 表单 -->
    <el-table
      :data="dataInfo.records"
      border
      highlight-current-row
      @current-change="currentChange"
      style="width: 100%"
    >
      <el-table-column
        v-for="item in cols"
        :key="item.prop"
        :prop="item.prop"
        :fixed="item.fixed"
        :label="item.label"
        :width="item.width"
      >
        <template #default="scops">
          <view class="fc fcenter" v-if="item.tp == 'img'">
            <el-image
              v-if="scops.row[item.prop] != null && scops.row[item.prop] != ''"
              fit="contain"
              style="width: 60px"
              :src="scops.row[item.prop]"
            />
            <view v-else>未设置</view>
          </view>
          <view v-else>
            <view class="fc fcenter" v-if="item.tp == 'emo'">
              <view >
                 {{ item.source.filter((row)=>{return row.codeValue==scops.row[item.prop]}).length>0?item.source.filter((row)=>{return row.codeValue==scops.row[item.prop]})[0].codeName:'' }}
                </view>
            </view>
            <view v-else>
              {{ scops.row[item.prop] }}
            </view>
          </view>
        </template>
      </el-table-column>
      <el-table-column v-if="$slots.colBtn" label="操作">
        <template #default="scops">
          <slot name="colBtn" :data="scops.row"></slot>
        </template>
      </el-table-column>
    </el-table>
    <div class="fr table-foot">
      <el-pagination
        v-if="pages > 0"
        page-size="10"
        :page-count="dataInfo.pages"
        :current-page="dataInfo.current"
        layout="prev, pager, next"
        :total="dataInfo.total"
        @current-change="pageChange"
      />
      <div v-else></div>
      <div class="fr table-row-button-group">
        <el-tooltip
          v-if="newButton"
          class="box-item"
          effect="dark"
          content="新增"
          placement="top-start"
        >
          <el-button circle @click="newRow"
            ><el-icon><Plus /> </el-icon
          ></el-button>
        </el-tooltip>
        <el-tooltip
          v-if="editButton"
          class="box-item"
          effect="dark"
          content="编辑"
          placement="top-start"
        >
          <el-button circle @click="editRow"
            ><el-icon><Edit /> </el-icon
          ></el-button>
        </el-tooltip>
        <el-tooltip
          v-if="deleteButton"
          class="box-item"
          effect="dark"
          content="删除"
          placement="top-start"
        >
          <el-button circle @click="deleteRow"
            ><el-icon><Delete /> </el-icon
          ></el-button>
        </el-tooltip>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import * as base from "../api/base.js";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "StTable",
  props: {
    search: {
      type: Boolean,
      default: true,
    },
    newButton: {
      type: Boolean,
      default: true,
    },
    editButton: {
      type: Boolean,
      default: true,
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
    cols: [],
    records: {
      type: Array,
      default: () => {
        return [];
      },
    },
    current: {
      type: Number,
      default: 1,
    },
    pages: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 1,
    },
    queryUrl: {
      type: String,
      default: "",
    },
  },
  watch: {
    records: {
      deep: true,
      handler: function (nv, ov) {
        this.dataInfo.records = nv;
      },
    },
    current: {
      handler: function (nv, ov) {
        this.dataInfo.current = nv;
      },
    },
    pages: {
      handler: function (nv, ov) {
        this.dataInfo.pages = nv;
      },
    },
    total: {
      handler: function (nv, ov) {
        // this.dataInfo.total = nv;
        this.dataInfo.total = nv;
      },
    },
  },
  data() {
    return {
      str: "",
      selectedRow: null,
      dataInfo: {
        records: this.records,
        current: this.current,
        pages: this.pages,
        total: this.total,
      },
    };
  },
  async mounted() {
    console.log(this.queryUrl);
    if (this.queryUrl) {
      await this.query(1, "");
    }
  },
  // watch:{
  //   queryUrl:{
  //     handler:function(nv,ov){
  //       if(nv){

  //         await  this.query(1,str);
  //       }
  //     }
  //   }
  // },
  methods: {
    currentChange(row) {
      this.selectedRow = row;
      //抛出选中行事件
      this.$emit("currentChange", this.selectedRow);
    },
    async doSearch() {
      if (this.queryUrl) {
        await this.query(1, this.str);
      } else {
        this.$emit("doSearch", this.str);
      }
    },
    async query(idx, str) {
      const { data } = await base.get(this.queryUrl, {
        str: str,
        pageIdx: idx,
        pageSize: 10,
      });
      this.dataInfo = data.data;
    },
    async pageChange(idx) {
      if (this.queryUrl) {
        await this.query(idx, this.str);
      } else {
        this.$emit("pageChange", idx, this.str);
      }
    },
    newRow() {
      this.$emit("newRow");
    },
    editRow() {
      if (this.selectedRow) {
        this.$emit("editRow", this.selectedRow);
      } else {
        ElMessage({
          message: "请先选择需要编辑的数据后点击",
          type: "warning",
        });
      }
    },
    deleteRow() {
      if (this.selectedRow) {
        this.$emit("deleteRow", this.selectedRow);
      } else {
        ElMessage({
          message: "请先选择需要删除的数据后点击",
          type: "warning",
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
