
import * as api from '../../api/room.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';
import roleView from '../../assets/modelView/roomView.json';
export default({
    name:"Room",
    data(){
        return {
            shopId:null,
            tableCols:[],
            queryForm:{
                pageIdx:1,
                pageSize:10,
                str:'',
                shopId:null,
            },
            dataList:{
                records:[],
                total:0,
                current:1,
                pages:1
            },
            roleFormInfo:{},
            roleInfo:{
                id:null,
                roleName:null,
            },
            roleFormRole:{

            },
            showDialog:false,
            cur:{}
        }
    },
    async mounted(){
        this.shopId = this.$route.query.shopId;
        this.queryForm.shopId =  this.$route.query.shopId;
        this.tableCols = roleView.tableCol;
        this.roleFormInfo = roleView.editForm;
        this.roleFormRole = formToRule(roleView.editForm);
        await this.queryRole();
    },
    methods:{
       async queryRole(){
            const {data} = await api.roomQuery(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList = data.data;
            }
       },
       async doSearch(str){
        this.queryForm.pageIdx = 1;
        this.queryForm.str = str;
        const {data} = await api.roomQuery(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList = data.data;
            }
       },
       async pageChange(idx,str){
        this.queryForm.pageIdx = idx;
        this.queryForm.str = str;
        const {data} = await api.roomQuery(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList = data.data;
            }
       },
       addRow(){
        this.roleInfo = {};
        this.showDialog = true;
       },
       editRow(row){
        this.roleInfo = JSON.parse(JSON.stringify(row));
        this.showDialog = true;
       },
       async saveForm(row){
            var  that = this;
            row.shopId = this.shopId;
            const {data} = await api.roomEdit(row);
            console.log(data);
            if(data.success){
                ElMessage({
                    message: '保存成功',
                    type: 'success',
                  });
                  that.$refs.roleTable.doSearch();
                  that.showDialog = false;
            }
            
        
          
       },
       dialogClose(){
        this.showDialog = false;
    },
    backPage(){
        this.$router.back();
    },
    rowChange(row){
        console.log(row);
        this.cur = JSON.parse(JSON.stringify(row));
    },
    async queryPack(){
        if(this.cur.id!=null){
            this.$router.push("/bu/pack?shopId="+this.shopId+"&roomId="+this.cur.id)
        }else{
            ElMessage({
                message: "请先选择需要编辑的数据后点击",
                type: "warning",
              });
        }
       
       },
    }
})