import paramView from '../../assets/modelView/param.json';
import { ElMessage, ElMessageBox } from 'element-plus'
import * as system from '../../api/system.js'
import { formToRule } from '../../utils/FormInfoUtils.js';
export default({
    name:"ParamAdmin",
    data(){
        return {
            tableCols:[],
            paramInfo:{
                id:'',
                paramType:'',
                paramId:'',
                paramValue:'',
                updateTime:'',
            },
            paramFormInfo:[],
            paramRule:{},
            showDialog:false,
        }
    },
    async mounted(){
        this.tableCols = paramView.tableCol;
        this.paramFormInfo = paramView.editForm;
        this.paramRule =formToRule(paramView.editForm);
    },
    methods:{
        editRow(row){
            this.paramInfo = JSON.parse(JSON.stringify(row));
            this.showDialog = true;
        },
        dialogClose(){
            this.showDialog = false;
        },
        async saveForm(row){
            var that = this;
            const { data } = await system.editParam(row);
            if (data.success) {
              ElMessage({
                  message: '保存成功',
                  type: 'success',
                });
                that.$refs.paramTable.doSearch();
                that.showDialog = false;
            }
        }
    }
})