<template>
    <div class="fc">
        <div class="page-title">参数管理</div>
        <st-table  style="width:100%" ref="paramTable" :cols="tableCols" 
        queryUrl="system/param/page" :newButton="false"
     @editRow="editRow"
      >
    </st-table>
    </div>
    <st-form :show="showDialog" cols="2" @dialogClose="dialogClose" @saveForm="saveForm" :formInfo="paramFormInfo" :formData="paramInfo" :rule="paramRule" ></st-form>
</template>
<script src='./ParamAdmin.js'></script>