<template>
  <el-dialog
    :show-close="false"
    :width="width"
    v-model="showDialog"
    :title="title"
    v-loading="loading"
  >
    <el-form
      ref="form"
      size="large"
      :model="formData"
      :rules="rule"
      label-width="100"
    >
      <el-row>
        <el-col v-for="item in formInfo" :key="item.prop" :span="span">
          <el-form-item v-if="compShow(item) " :label="item.label" :prop="item.prop">
            <!-- 常规输入框-->
            <el-input v-if="item.tp == 'input'" v-model="formData[item.prop]" />
            <!-- 密码输入框-->
            <el-input
              v-if="item.tp == 'password'"
              type="password"
              show-password
              v-model="formData[item.prop]"
            />
            <!-- 时间选择-->
            <el-time-picker
            v-if="item.tp=='time'"
            v-model="formData[item.prop]"
      placeholder="请选择时间"
      value-format="HH:mm"
    />
            <!-- 日期选择-->
            <el-date-picker
            v-if="item.tp=='date'"
        v-model="formData[item.prop]"
        type="date"
        value-format="YYYY-MM-DD"
        placeholder="选择一个日期"
      />
            <!-- textarea-->
            <el-input
              v-if="item.tp == 'textarea'"
              type="textarea"
              v-model="formData[item.prop]"
            />
            <!-- 数字输入框-->
            <el-input-number
              v-if="item.tp == 'number'"
              v-model="formData[item.prop]"
              :precision="item.prec"
              :step="item.step"
              :max="item.max"
            />
            <!-- 下拉选择(单选/多选)-->
            <el-select
              v-if="item.tp == 'select'"
              :multiple="item.multiple"
              :multiple-limit="item.multipleLimit"
              v-model="formData[item.prop]"
            >
              <el-option
                v-for="subItem in item.source"
                :key="subItem.codeValue"
                :label="subItem.codeName"
                :value="subItem.codeValue"
              ></el-option>
            </el-select>
            <div>
              <quill-editor
                v-if="item.tp == 'richText'"
                ref="myQuillEditor" style="min-height:300px"
                v-model:content="formData[item.prop]"
                theme="snow"
                contentType="html"
                :options="editorOption"
                v-on:ready="onQuillReady(quill)"
              ></quill-editor>
              <input
                type="file"
                hidden
                accept="image/*"
                ref="imgBtn"
                multiple="multiple"
                @change="handleImgUpload"
              />
              <input
                type="file"
                hidden
                accept="video/*"
                ref="videoBtn"
                multiple="multiple"
                @change="handleVideoUpload"
              />
            </div>

            <div v-if="item.tp == 'images'" style="position: relative">
              <div v-if="item.max == 1">
                <div v-if="formData[item.prop] != null" class="image-box">
                  <el-image
                    @click="uploadClick(item.prop)"
                    style="width: 100px"
                    :src="formData[item.prop]"
                  ></el-image>
                  <div
                    @click="uploadClick(item.prop)"
                    class="fc fcenter image-hove"
                    style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      background-color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    <el-icon style="color: white"><Upload /></el-icon>
                  </div>
                </div>

                <div v-else class="btn" @click="uploadClick(item.prop)">
                  <el-icon
                    style="
                      border: 1px solid var(--m-color);
                      width: 50px;
                      height: 50px;
                      border-radius: 10px;
                    "
                    ><Plus
                  /></el-icon>
                </div>
              </div>
              <div v-else>
                <div class="fr fcenter" >

                  <div v-if="formData[item.prop] != null" class="fr " style="flex-wrap:wrap;justify-content: flex-start;" >
                  <view 
                    v-for="(i, index) in formData[item.prop]"
                    :key="i"
                    class="image-box fr fcenter" style="width:100px;height:100px;"
                  >
                    <el-image style="width: 100px; height: 100px" fit="contain" :src="i"></el-image>
                    <div
                      @click="deleteImage(item.prop, index)"
                      class="fc fcenter image-hove"
                      style="
                        position: absolute;
                        left: 0;
                        width: 100px;
                        height: 100px;
                        background-color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      <el-icon style="color: white"><Delete /></el-icon>
                    </div>
                  </view>
                </div>
                <div
                  @click="uploadClick(item.prop)"
                  v-if="
                    formData[item.prop] == null ||
                    formData[item.prop].length < item.max
                  "
                  class="btn"
                >
                  <el-icon
                    style="
                      border: 1px solid var(--m-color);
                      width: 50px;
                      height: 50px;
                      border-radius: 10px;
                    "
                    ><Plus
                  /></el-icon>
                </div>
                </div>
                
              </div>

              <input
                type="file"
                hidden
                accept="image/*"
                :ref="'singUpload' + item.prop"
                @change="
                  (value) => {
                    singleUpload(value, item.prop, item.max);
                  }
                "
              />
            </div>

            <!-- 文件上传-->
            <StTable
              v-if="item.tp == 'files'"
              style="width: 100%"
              :ref="item.prop"
              :cols="fileTableCols"
              :records="formData[item.prop]"
              :search="false"
              :deleteButton="true"
              :editButton="false"
              @newRow="addFile(item.prop)"
              @deleteRow="deleteFile(item.prop)"
            ></StTable>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="show = false">取消</el-button>
        <el-button type="primary" @click="saveForm"> 保存 </el-button>
      </span>
    </template>
  </el-dialog>
  <input
    id="fileInput"
    type="file"
    style="display: none"
    @change="onFileChange"
  />
  <el-dialog v-model="showImgDialog">
    <el-image :src="showImgUrl" />
  </el-dialog>
</template>
<script>
import * as system from "../api/system.js";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ElLoading } from 'element-plus'
export default {
  components: { QuillEditor },
  name: "StForm",
  setup() {},
  props: {
    width: {
      type: Number,
      default: 1000,
    },
    cols: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: "编辑",
    },
    formInfo: {
      type: Array,
      default: () => {
        return {};
      },
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    rule: {
      type: Object,
      default: () => {
        return {};
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: {
      handler: function (nv, ov) {
        this.showDialog = nv;
      },
    },
    formInfo: {
      handler: async function (nv, ov) {
        console.log(this.formInfo);
        for (var i = 0; i < this.formInfo.length; i++) {
          let _row = this.formInfo[i];
          if (_row.tp == "select" && _row.codeType) {
            _row.source = await this.getCode(_row.codeType);
          }
        }
      },
    },
    formData: {
      deep: true,
      handler: function (nv, ov) {
        // console.log(nv);
      },
    },
    showDialog: {
      handler(nv, ov) {
        console.log(nv);
        if (!nv) {
          this.$emit("dialogClose");
        }
      },
    },
  },
  data() {
    return {
      showDialog: false,
      span: 24,
      headers: {
        token: sessionStorage.getItem("token"),
      },
      uploadUrl: process.env.VUE_APP_API_URL + "system/uploadFile",
      showImgDialog: false,
      showImgUrl: "",
      fileTableCols: [
        { prop: "name", label: "文件名", fixed: true, width: "220" },
        { prop: "uploadTime", label: "上传时间", fixed: true, width: "220" },
      ],
      selectedProp: "",
      loading:null,
      content: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ size: ["small", false, "large", "huge"] }],
            [{ font: [] }],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ header: 1 }, { header: 2 }],
            ["image", "video"],
            [{ direction: "rtl" }], //文字编辑方向，从左到右还是从右到左
            [{ color: [] }, { background: [] }],
          ],
        },
        placeholder: "请输入内容...",
      },
    };
  },
  mounted() {
    this.span = 24 / this.cols;
  },
  updated() {
    // console.log(this.$refs.myQuillEditor)
    //     if (this.$refs.myQuillEditor) {
    //       let quill = this.$refs.myQuillEditor[0].getQuill();
    //        quill.getModule("toolbar").addHandler("image", this.imgHandler);
    //        quill.getModule("toolbar").addHandler("video", this.videoHandler);
    //      }
  },
  methods: {
    deleteImage(prop,index){
      this.formData[prop].splice(index,1);
    },
    uploadClick(e) {
      console.log(e);
      this.$refs["singUpload" + e][0].click();
    },
    onQuillReady(e) {
      let quill = this.$refs.myQuillEditor[0].getQuill();
      quill.getModule("toolbar").addHandler("image", this.imgHandler);
      quill.getModule("toolbar").addHandler("video", this.videoHandler);
    },
    saveForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.formData);
          this.$emit("saveForm", this.formData);
        }
      });
    },
    async getCode(typeId) {
      const { data } = await system.getCode({
        codeType: typeId,
        str: "",
        needAll: "0",
      });
      return data.data;
    },
    handlePictureCardPreview(file) {
      console.log(file);
      this.showImgUrl = file.url;
      this.showImgDialog = true;
    },
    handleUploadSuccess(res, prop) {
      console.log(res.data.url);
      if (this.formData[prop] == null) {
        this.formData[prop] = [];
      }
      if (res.success) {
        // this.formData[prop].pop();
        this.formData[prop].push(res.data.url);
      }
    },
    addFile(prop) {
      console.log(prop);
      this.selectedProp = prop;
      const input = document.querySelector("#fileInput");
      input.click();
    },
    deleteFile(prop) {
      this.formData[prop].splice(
        this.formData[prop].indexOf(this.$refs[prop][0].selectedRow),
        1
      );
      // this.formData[prop].pop(this.$refs[prop][0].selectedRow)
    },
    async onFileChange(event) {
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      const { data } = await system.uploadFile(formData);
      console.log(data);
      if (data.success) {
        this.formData[this.selectedProp].push(data.data);
      }
    },
    imgHandler(state) {
      if (state) {
        this.$refs.imgBtn[0].click();
      }
    },
    createFormData(files) {
      var formData = new FormData();
      formData.append("name", files.name);
      formData.append("type", files.type);
      formData.append("size", files.size);
      formData.append("lastModifiedDate", files.lastModifiedDate);
      formData.append("file", files);
      return formData;
    },
    async handleImgUpload(e) {
      this.loading = true;
      let params = this.createFormData(e.target.files[0]);
      const { data: res } = await system.uploadFile(params);
      let quill = this.$refs.myQuillEditor[0].getQuill();
      let length = quill.getSelection().index;
      console.log(res);
      quill.insertEmbed(length, "image", res.data.url);
      console.log(quill.container);
      quill.setSelection(length + 1);
      this.loading =false;
    },

    videoHandler(state) {
      if (state) {
        this.$refs.videoBtn[0].click();
      }
    },
    async singleUpload(e, prop, max) {
      this.loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  });
      let params = this.createFormData(e.target.files[0]);
      const { data: res } = await system.uploadFile(params);
      if (max == 1) {
        this.formData[prop] = res.data.url;
      } else {
        if (this.formData[prop] == null) {
          this.formData[prop] = [];
        }
        this.formData[prop].push(res.data.url);
      }
      this.loading.close();
    },

    async handleVideoUpload(e) {
      let params = this.createFormData(e.target.files[0]);
      const { data: res } = await system.uploadFile(params);
      let quill = this.$refs.myQuillEditor[0].getQuill();
      let length = quill.getSelection().index;
      quill.insertEmbed(length, "video", res.data.url);
      quill.setSelection(length + 1);
    },
    compShow(item){
      if(item.display){
        let display = true;
        //
        console.log(item.display)
        for(let key in item.display){
          console.log(key +" "+ item.display[key]);
          display = this.formData[key] ==item.display[key];
          if(!display){
            return display;
          }
        }
        
      }
      return true;
    }
  },
};
</script>
<style scoped>
.el-input-number,
.el-select {
  width: 100%;
}
</style>