import axios from "axios";
import http from "../utils/http";

//编辑新闻
export const editNews = (data) => {
    return http({
        url: "news/editNews",
        method: "POST",
        data,
    })
}
export const queryNews = (data) => {
    return http({
        url: "news/queryNews",
        method: "GET",
        data,
    })
}
export const getNews = (data) => {
    return http({
        url: "news/getNews",
        method: "GET",
        data,
    })
}
