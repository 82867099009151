
import * as system from '../../api/system.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';
import roleView from '../../assets/modelView/role.json';
export default({
    name:"RoleAdmin",
    data(){
        return {
            tableCols:[],
            queryForm:{
                pageIdx:1,
                pageSize:10,
                str:''
            },
            dataList:{
                records:[],
                total:0,
                current:1,
                pages:1
            },
            roleFormInfo:{},
            roleInfo:{
                id:null,
                roleName:null,
            },
            roleFormRole:{

            },
            showDialog:false
        }
    },
    async mounted(){
        this.tableCols = roleView.tableCol;
        this.roleFormInfo = roleView.editForm;
        this.roleFormRole = formToRule(roleView.editForm);
        await this.queryRole();
    },
    methods:{
       async queryRole(){
            const {data} = await system.getRole(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList = data.data;
            }
       },
       addRow(){
        this.showDialog = true;
       },
       editRow(row){
        this.roleInfo = JSON.parse(JSON.stringify(row));
        this.showDialog = true;
       },
       async saveForm(row){
            var  that = this;
            const {data} = await system.editRole(row);
            if(data.success){
                ElMessage({
                    message: '保存成功',
                    type: 'success',
                  });
                  that.$refs.roleTable.doSearch();
                  that.showDialog = false;
            }
       },
       dialogClose(){
        this.showDialog = false;
    },
    }
})