<template>
    <div class="fc">
      <div class="page-title">店铺管理</div>
      <st-table style="width:100%" ref="roleTable" :cols="tableCols" 
        :records="dataList.records"
        :current="dataList.current"
        :pages="dataList.pages"
        :total="dataList.total"
        queryUrl="pack/shopList"
        @currentChange="rowChange"
        @newRow = "addRow" @editRow="editRow"
        >
        <el-tooltip 
              class="box-item"
              effect="dark"
              content="产品列表"
              placement="top-start"
            >
              <el-button circle @click="queryPack"
                ><el-icon><Place/> </el-icon
              ></el-button>
            </el-tooltip>
            <el-tooltip 
              class="box-item"
              effect="dark"
              content="房间列表"
              placement="top-start"
            >
              <el-button circle @click="queryRoom"
                ><el-icon><Grid/> </el-icon
              ></el-button>
            </el-tooltip>
      </st-table>
      <st-form :show="showDialog" @dialogClose="dialogClose" @saveForm ="saveForm" :formInfo="roleFormInfo" :formData="roleInfo" :rule="roleFormRole" ></st-form>
   </div>
  </template>
  <script src='./Shop.js'></script>
  