
import * as pack from '../../api/pack.js'
import { ElDialog, ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';
import roleView from '../../assets/modelView/order.json';
export default({
    name:"Order",
    data(){
        return {
            tableCols:[],
            queryForm:{
                pageIdx:1,
                pageSize:10,
                str:''
            },
            dataList:{
                records:[],
                total:0,
                current:1,
                pages:1
            },
            
            showDialog:false,
            roleFormInfo:{},
            roleInfo:{
                id:null,
                roleName:null,
            },
            roleFormRole:{

            },
            curOrder:{},
            refundDialog:false,
            refundInfo:{},
            refundRole:{},
            loading:null,
        }
    },
    async mounted(){
        this.tableCols = roleView.tableCol;
        this.roleFormInfo = roleView.editForm;
        this.roleFormRole = formToRule(roleView.editForm);
    },
    methods:{
       async queryRole(){
            const {data} = await system.getRole(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList = data.data;
            }
       },
       editRow(row){
        this.roleInfo = JSON.parse(JSON.stringify(row));
        this.showDialog = true;
       },
       async saveForm(row){
        var  that = this;
            const {data} = await pack.editOrderRemark(row);
            if(data.success){
                ElMessage({
                    message: '保存成功',
                    type: 'success',
                  });
                  that.$refs.roleTable.doSearch();
                  that.showDialog = false;
            }
    
      
   },
   rowChange(row){
    this.curOrder = JSON.parse(JSON.stringify(row));
},
   async refund(){
    if(this.curOrder){
        const data = await pack.getRefund({
            payInfoId:this.curOrder.payInfoId
        })
        console.log(data.data.data)
        this.refundInfo = data.data.data;
        if(!this.refundInfo.refundAmount){
            this.refundInfo.refundAmount = this.curOrder.finalAmount;
        }
        this.refundDialog = true;
        
    }else{
        ElMessage({
            message: "请先选择需要编辑的数据后点击",
            type: "warning",
          });
    }
   },
   async startRefund(){
    if(this.refundInfo.refundStatus!='1'){
        this.refundInfo.payInfoId = this.curOrder.payInfoId;
        const data = await pack.createRefund(this.refundInfo);
        console.log(data.data);
        if(data.data.success){
            ElMessage({
                message: "退款发起成功",
                type: "success",
              });
        }
        this.refund();
        this.$refs.roleTable.doSearch();
    }
  

   },
   cancelOrder(){
    let that = this;
    if(this.curOrder){
        if(this.curOrder.scheStatus=="1"){
            ElMessage({
                message: "该用户已到场,不可取消预约",
                type: "warning",
              });
        }else{
          if(this.curOrder.scheStatus){
            ElMessageBox.confirm(
                '是否确定取消用户预约或排队?',
                '提示',
                {
                  confirmButtonText: 'OK',
                  cancelButtonText: 'Cancel',
                  type: 'warning',
                }
              )
                .then(async () => {
                  const data = await pack.cancelOrder({
                    id:that.curOrder.id
                  });
                  console.log(data);
                  if(data.data.success){
                    ElMessage({
                        message: "取消成功",
                        type: "success",
                      });
                      that.$refs.roleTable.doSearch();
                  }
                })
                .catch(() => {
                  
                })
          }else{
            ElMessage({
                message: "该订单尚未预约服务",
                type: "warning",
              });
          }
        }
    }else{
        ElMessage({
            message: "请先选择需要编辑的数据后点击",
            type: "warning",
          });
    }
   },
       dialogClose(){
        this.showDialog = false;
    },
    },
     
})