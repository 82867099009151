<template>
  <div class="fc">
    <div class="page-title">角色管理</div>
    <st-table style="width:100%" ref="roleTable" :cols="tableCols" 
      :records="dataList.records"
      :current="dataList.current"
      :pages="dataList.pages"
      :total="dataList.total"
      queryUrl="system/role/page"

      @newRow = "addRow" @editRow="editRow"
      >
      <el-tooltip 
            class="box-item"
            effect="dark"
            content="编辑权限"
            placement="top-start"
          >
            <el-button circle @click="editAuth"
              ><el-icon><Place/> </el-icon
            ></el-button>
          </el-tooltip>
    </st-table>
    <st-form :show="showDialog" @dialogClose="dialogClose" @saveForm ="saveForm" :formInfo="roleFormInfo" :formData="roleInfo" :rule="roleFormRole" ></st-form>
 </div>
</template>
<script src='./RoleAdmin.js'></script>
