<template>
    <div class="fc">
      <div class="page-title fr f-space" style="width: 100%">
      <div class="fr">
        <div @click="backPage" class="back-icon">
          <el-icon><Back></Back></el-icon>
        </div>
        门店排期
      </div>
      <div class="fr">
        <el-select
              :multiple="false"
              v-model="shopId"
              @change="shopDateSche"
            >
              <el-option
                v-for="item in shopList"
                :key="item.id"
                :label="item.shopName"
                :value="item.id"

              ></el-option>
            </el-select>

        <el-button  @click="setDate(-1)"   >{{ '<' }}</el-button>
        <el-date-picker
        v-model="scheDate"
        type="date"
        @change="shopDateSche"
        value-format="YYYY-MM-DD"
        placeholder="选择一个日期"
      />
        <el-button  @click="setDate(+1)"  >{{ '>' }}</el-button>
      </div>
      
    </div>
    <view class="fr">
      <view v-for="item in packList" :key="item.scheDate" class="scheDateBox pack-box">
        <view
          class="date-box-title"
          style="justify-content: space-between; padding: 4px 10px"
          >{{ item.packName }}
          </view>
            <el-table style="height:500px;" v-if="item.packType=='0'"
            :data="item.sches"
            empty-text="今日无预约">
            <el-table-column label="状态" fixed width="80" prop="scIdx">
              <template #default="scops">
                <view v-if="scops.row.orderStatus=='0'">待入场</view>
                 <view v-if="scops.row.orderStatus=='1'" style="color:var(--m-color)">完成</view>
        </template>
            </el-table-column>
            <el-table-column label="入场时间" width="100" prop="stTime"></el-table-column>
            <el-table-column label="离场时间" width="100" prop="edTime"></el-table-column>
            <el-table-column label="预约人" width="120" prop="nickName"></el-table-column>
            <el-table-column label="手机号码" width="120" prop="telNumber"></el-table-column>
            <el-table-column label="会员号" width="120" prop="userNumber"></el-table-column>
            <el-table-column label="支付金额" width="100" prop="payPrice"></el-table-column>
            <el-table-column label="操作" width="120" fixed="right">
              <template #default="scops">
                <el-popconfirm
              @confirm="orderArea(scops.row)"
              title="用户是否到场?"
            >
              <template #reference>
                <el-button >到场</el-button>
              </template>
            </el-popconfirm>
        </template>
            </el-table-column>
            </el-table>

            <el-table style="height:500px;" v-if="item.packType=='1'"
            :data="item.scs"
            empty-text="今日无预约">
            <el-table-column label="预约号" fixed width="80" prop="scIdx">
              <template #default="scops">
                {{ item.packIdx }}{{ scops.row.scIdx }}
        </template>
            </el-table-column>
            <el-table-column label="状态" fixed width="80" prop="scIdx">
              <template #default="scops">
                <view v-if="scops.row.scStatus=='0'">排队</view>
                 <view v-if="scops.row.scStatus=='2'" style="color:var(--m-color)">过号</view>
                 <view v-if="scops.row.scStatus=='1'" style="color:var(--m-color)">完成</view>
        </template>
            </el-table-column>
            <el-table-column label="预约人" width="120" prop="nickName"></el-table-column>
            <el-table-column label="手机号码" width="120" prop="telNumber"></el-table-column>
            <el-table-column label="会员号" width="120" prop="userNumber"></el-table-column>
            <el-table-column label="支付金额" width="100" prop="payPrice"></el-table-column>
            <el-table-column label="状态" width="100" prop="payPrice"></el-table-column>
            <el-table-column label="操作" width="150" fixed="right">
              <template #default="scops">
                <view class="fr" style="gap:0">
                  <el-popconfirm
              @confirm="inArea(scops.row)"
              title="是否设置进场?"
            >
              <template #reference>
                <el-button >进场</el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm
              @confirm="ourArea(scops.row)"
              title="是否设置过号?"
            >
              <template #reference>
                <el-button >过号</el-button>
              </template>
            </el-popconfirm>
              
                </view>
        </template>
            </el-table-column>
            </el-table>
      </view>
    </view>
    
   </div>
  </template>
  <script src='./Sc.js'></script>
  