
import * as system from '../../api/system.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';
import roleView from '../../assets/modelView/user.json';
export default({
    name:"userAdmin",
    data(){
        return {
            tableCols:[],
            queryForm:{
                pageIdx:1,
                pageSize:10,
                str:''
            },
            dataList:{
                records:[],
                total:0,
                current:1,
                pages:1
            },
            roleFormInfo:{},
            roleInfo:{
                id:null,
                roleName:null,
            },
            roleFormRole:{

            },
            showDialog:false,
            cur:{},

            passWordDialog:false,
            passFormInfo:{},
            passInfo:{
                pwd:'1',
                pwd1:'2',
            },passFormRole:{}
        }
    },
    async mounted(){
        this.tableCols = roleView.tableCol;
        this.roleFormInfo = roleView.editForm;
        this.roleFormRole = formToRule(roleView.editForm);
        this.passFormInfo = roleView.pwdForm;
        this.passFormRole = formToRule(roleView.pwdForm);
        // await this.queryRole();
    },
    methods:{
        passWordDialogClose(){
            this.passWordDialog = false;
        },
        currentChange(row){
            this.cur = row;
        },
       async queryRole(){
            const {data} = await system.getRole(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList = data.data;
            }
       },
       addRow(){
        this.roleInfo = {};
        this.showDialog = true;
       },
       editRow(row){
        this.roleInfo = JSON.parse(JSON.stringify(row));
        this.showDialog = true;
       },
       async saveForm(row){
            var  that = this;
            if(row.id!=null){
                const {data} = await system.editUser(row);
                if(data.success){
                    ElMessage({
                        message: '保存成功',
                        type: 'success',
                      });
                      that.$refs.roleTable.doSearch();
                      that.showDialog = false;
                }
            }else{
               const   {data} = await system.createUser(row);
               if(data.success){
                ElMessage({
                    message: '保存成功',
                    type: 'success',
                  });
                  that.$refs.roleTable.doSearch();
                  that.showDialog = false;
            }
            }
        
          
       },
       editPwd(){
        if(this.cur.id==null){
            ElMessage({
                message:"请选择用户后操作"
            })
        }else{
            this.passInfo ={
                pwd:'',
                pwd1:''
            }
            this.passWordDialog = true;
        }
       },
       async savePwdForm(){
        console.log(this.passInfo);
        if(this.passInfo.pwd == this.passInfo.pwd1){
            const {data} = await system.editUserPwd({
                id:this.cur.id,
                userPwd:this.passInfo.pwd
            });
            ElMessage({
                message:"修改成功",
                type:"success"
            })
            this.passWordDialog = false;
        }else{
            ElMessage({
                message:"两次输入的不一致"
            })
        }
       },
       dialogClose(){
        this.showDialog = false;
    },
    }
})