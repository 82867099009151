<template>
  <div class="fc">
    <div class="page-title fr f-space" style="width: 100%">
      <div class="fr">
        <div @click="backPage" class="back-icon">
          <el-icon><Back></Back></el-icon>
        </div>
        排期管理
      </div>
      <div class="fr">
        <el-button @click="addSche">新建</el-button>
      <el-button @click="autoCreate">自动初始化</el-button>
      </div>
      
    </div>

    <view class="fr">
      <view v-for="item in dataList" :key="item.scheDate" class="scheDateBox">
        <view
          class="date-box-title"
          style="justify-content: space-between; padding: 4px 10px"
          >{{ item.scheDate }}

          <el-popconfirm
              @confirm="deleteDay(item)"
             
              title="是否要删除当前全部排期?"
            >
              <template #reference>
                <el-button size="small">删除</el-button>
              </template>
            </el-popconfirm>
</view
        >
        <view class="fc box-main">
          <view v-for="i in item.scheduList" :key="i" class="time-line">
            <view style="margin-right: 6px"
              >{{ i.stTime }} - {{ i.edTime }}
            </view>
            <el-button
              v-if="i.orderer == null"
              @click="editSche(i)"
              size="small"
              >编辑</el-button
            >
            <el-popconfirm
              @confirm="deleteSche(i)"
              v-if="i.orderer == null"
              title="是否要删除?"
            >
              <template #reference>
                <el-button size="small">删除</el-button>
              </template>
            </el-popconfirm>
            <!-- <el-button v-if="i.orderer==null" @click="deleteSche(i)" size="small">删除</el-button> -->
            <view v-else>已预约</view>
          </view>
        </view>
      </view>
    </view>

    <st-form
      :show="showDialog"
      @dialogClose="dialogClose"
      @saveForm="saveForm"
      :formInfo="roleFormInfo"
      :formData="roleInfo"
      :rule="roleFormRole"
    ></st-form>

    <st-form
      :show="showCreateDialog"
      @dialogClose="AutodialogClose"
      @saveForm="autoSaveForm"
      :formInfo="autoFormInfo"
      :formData="autoInfo"
      :rule="autoFormRole"
    ></st-form>

    <st-form
      :show="scheDialog"
      @dialogClose="scheDialogClose"
      @saveForm="scheSaveForm"
      :formInfo="scheFormInfo"
      :formData="scheInfo"
      :rule="scheFormRole"
    ></st-form>
  </div>
</template>
  <script src='./Sche.js'></script>
  