import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import *  as ElementPlusIconsVue from "@element-plus/icons-vue";
import StTable from './components/StTable.vue';
import StForm from './components/StForm.vue';
const app = createApp(App)
app.use(store).use(router).use(ElementPlus).use(ElementPlusIconsVue)
for(const [key,component] of Object.entries(ElementPlusIconsVue)){
    app.component(key,component);
}
app.component('StTable',StTable);
app.component('StForm',StForm);
app.mount('#app')