<template>
  <div class="fc">
    <div class="page-title">用户管理</div>
    <st-table style="width:100%" ref="roleTable" :cols="tableCols" 
      :records="dataList.records"
      :current="dataList.current"
      :pages="dataList.pages"
      :total="dataList.total"
      queryUrl="system/queryUser"
      @currentChange="currentChange"

      @newRow = "addRow" @editRow="editRow"
      >
      <el-tooltip 
            class="box-item"
            effect="dark"
            content="编辑角色"
            placement="top-start"
          >
            <el-button circle @click="editAuth"
              ><el-icon><Place/> </el-icon
            ></el-button>
          </el-tooltip>
          <el-tooltip 
            class="box-item"
            effect="dark"
            content="重置密码"
            placement="top-start"
          >
            <el-button circle @click="editPwd"
              ><el-icon><EditPen/> </el-icon
            ></el-button>
          </el-tooltip>
    </st-table>
    <st-form :show="passWordDialog" @dialogClose="passWordDialogClose" @saveForm="savePwdForm" :formInfo="passFormInfo" :formData="passInfo" :rule="passFormRole" ></st-form>
    <st-form :show="showDialog" @dialogClose="dialogClose" @saveForm ="saveForm" :formInfo="roleFormInfo" :formData="roleInfo" :rule="roleFormRole" ></st-form>
 </div>
</template>
<script src='./UserAdmin.js'></script>
