import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import CodeAdmin from '../views/CodeAdmin/CodeAdmin.vue'
import ParamAdmin from '../views/ParamAdmin/ParamAdmin.vue'
import RoleAdmin from '../views/RoleAdmin/RoleAdmin.vue'
import UserAdmin from '../views/UserAdmin/UserAdmin.vue'
import Sc from '../views/Sc/Sc.vue'
import News from '../views/News/News.vue'
import Shop from '../views/shop/Shop.vue'
import pack from '../views/Pack/Pack.vue'
import Sku from '../views/Sku/Sku.vue'
import Sche from '../views/Sche/Sche.vue'
import Order from '../views/Order/Order.vue'
import Photo from '../views/Photo/Photo.vue'
import Coupon from '../views/Coupon/Coupon.vue'
import RoomAdmin from '../views/room/RoomAdmin.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Sc
  },{
    path: '/system/param',
    name: 'ParamAdmin',
    component: ParamAdmin
  },{
    path: '/system/user',
    name: 'UserAdmin',
    component: UserAdmin
  },{
    path: '/system/role',
    name: 'RoleAdmin',
    component: RoleAdmin
  },{
    path: '/bu/sc',
    name: 'sc',
    component: Sc
  },{
    path: '/bu/news',
    name: 'news',
    component: News
  },{
    path: '/bu/shop',
    name: 'shop',
    component: Shop
  },{
    path: '/bu/pack',
    name: 'pack',
    component: pack
  },{
    path: '/bu/sku',
    name: 'sku',
    component: Sku
  },{
    path: '/bu/sche',
    name: 'sche',
    component: Sche
  },{
    path: '/bu/order',
    name: 'order',
    component: Order
  },{
    path: '/bu/photo',
    name: 'photo',
    component: Photo
  },{
    path: '/bu/coupon',
    name: 'coupon',
    component: Coupon
  },{
    path: '/bu/roomAdmin',
    name: 'roomAdmin',
    component: RoomAdmin
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
