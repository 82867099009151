
import * as coupon from '../../api/coupon.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';
import roleView from '../../assets/modelView/Coupon.json';
export default({
    name:"Coupon",
    data(){
        return {
            tableCols:[],
            queryForm:{
                pageIdx:1,
                pageSize:10,
                str:''
            },
            dataList:{
                records:[],
                total:0,
                current:1,
                pages:1
            },
            roleFormInfo:{},
            roleInfo:{
                id:null,
                roleName:null,
            },
            roleFormRole:{

            },
            showDialog:false
        }
    },
    async mounted(){
        this.tableCols = roleView.tableCol;
        this.roleFormInfo = roleView.editForm;
        this.roleFormRole = formToRule(roleView.editForm);
        // await this.queryRole();
    },
    methods:{
       addRow(){
        this.roleInfo = {};
        this.showDialog = true;
       },
       async editRow(row){
        console.log(row);
        const {data} = await coupon.getById(row);
        console.log(data);

        this.roleInfo = JSON.parse(JSON.stringify(data.data));
        this.showDialog = true;
       },
       async saveForm(row){
            var  that = this;
            const   {data} = await coupon.editCp(row);
               if(data.success){
                ElMessage({
                    message: '保存成功',
                    type: 'success',
                  });
                  that.$refs.roleTable.doSearch();
                  that.showDialog = false;
            }
        
          
       },
       dialogClose(){
        this.showDialog = false;
    },
    }
})