<template>
    <div class="fc" >
      <div class="page-title">照片管理</div>
      <st-table style="width:100%" ref="roleTable" :cols="tableCols" 
        :records="dataList.records"
        :current="dataList.current"
        :pages="dataList.pages"
        :total="dataList.total"
        queryUrl="pack/queryOrderPhoto"
        @currentChange="currentChange"
        :newButton="false"
        :editButton="false"
        >
        <el-tooltip 
              class="box-item"
              effect="dark"
              content="查看/上传照片"
              placement="top-start"
            >
              <el-button circle @click="showImage"
                ><el-icon><Upload/> </el-icon
              ></el-button>
            </el-tooltip>
      </st-table>
      <el-dialog title="照片" v-model="showDialog">
        <div class="fr" style="justify-content: flex-start;min-height: 200px;flex-wrap: wrap;">
            <div class="image-box" v-for="item in photoList" :key="item">
                <el-image style="width: 100px;height:100px"  fit="cover" :src="item.phoneUrl+'?x-oss-process=image/resize,w_200,m_lfit'"></el-image>
                <div class=" fr fcenter image-hove"    style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      background-color: rgba(0, 0, 0, 0.5);
                    ">
                     <el-popconfirm
              @confirm="removeImage(item)"
              title="是否要删除?"
            >
              <template #reference>
                <el-icon style="color: white"><Delete /></el-icon>
                
              </template>
            </el-popconfirm>
            <el-icon @click="showBigImage(item)" style="color: white"><ZoomIn /></el-icon>
                </div>
            </div>
            <input
                type="file"
                hidden
                accept="image/*"
                ref="imgBtn"
                multiple="multiple"
                @change="handleImgUpload"
              />
            <div  class="btn" @click="uploadClick">
                  <el-icon
                    style="
                      border: 1px solid var(--m-color);
                      width: 100px;
                      height: 100px;
                      border-radius: 10px;
                    "
                    ><Plus
                  /></el-icon>
                </div>
        </div>

      </el-dialog>
      <el-dialog v-loading="loading" title="正在上传" style="width:200px;height:150px;" v-model="loadingDialog" :show-close="false" :close-on-click-modal="false">
        <view style="display: flex; width:180px;align-items: center;justify-content: center;font-size:24px">{{ curSize }}/{{ maxSize }}</view>
      </el-dialog>
      <el-dialog title="查看照片"  v-model="showImageDialog">
       <el-image :src="image.phoneUrl"></el-image>
      </el-dialog>
   </div>
  </template>
  <script src='./Photo.js'></script>
  