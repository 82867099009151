import axios from "axios";
import http from "../utils/http";

export const login = (data) => {
    return http({
        url: "system/login",
        method: "POST",
        data,
    })
}
export const userAuth = (data) => {
    return http({
        url: "system/getAuth",
        method: "GET",
        data,
    })
}
export const editUser = (data) => {
    return http({
        url: "system/editUser",
        method: "POST",
        data,
    })
}

export const createUser = (data) => {
    return http({
        url: "system/createUser",
        method: "POST",
        data,
    })
}

export const comUserRemove = (data) => {
    return http({
        url: "system/comUser/remove",
        method: "POST",
        data,
    })
} 

export const getCodeType =  (data) => {
    return http({
        url: "system/getCodeType",
        method: "GET",
        data,
    })
} 

export const getCode =  (data) => {
    return http({
        url: "system/getCode",
        method: "GET",
        data,
    })
} 

export const getParam = (data)=>{
    return http({
        url:"param/page",
        method:"GET",
        data,
    })
}
export const editParam=(data)=>{
    return http({
        url:"system/param/update",
        method:"POST",
        data,
    })
}

export const uploadOss = (url,data)=>{
    return axios({
        url: url,
        method: "POST",
        headers:{
            'Content-Type':'multipart/form-data'
        },
        data
    })
}

export const uploadFile = (data)=>{
    return axios({
        baseURL: process.env.VUE_APP_API_URL, //接口统一域名
        url: "system/uploadFile",
        method: "POST",
        headers:{
            'Content-Type':'multipart/form-data',
            'token':sessionStorage.getItem('token')
        },
        data
    })
}
// 017.查询角色
export const getRole = (data)=>{
    return http({
        url:"system/role/page",
        method:"GET",
        data,
    })
}
// 018.编辑角色
export const editRole = (data)=>{
    return http({
        url:"system/role/edit",
        method:"POST",
        data,
    })
}


// 019.查询角色权限
export const getRoleAuth = (data)=>{
    return http({
        url:"system/role/auth",
        method:"GET",
        data,
    })
}
// 020.查询全部权限
export const getAuth = (data)=>{
    return http({
        url:"system/auth/all",
        method:"GET",
        data,
    })
}

// 021.添加角色权限
export const addRoleAuth = (data)=>{
    return http({
        url:"system/role/auth/add",
        method:"POST",
        data,
    })
}
// 022.删除角色权限
export const deleteRoleAuth = (data)=>{
    return http({
        url:"system/role/auth/delete",
        method:"POST",
        data,
    })
}

export const editUserPwd = (data)=>{
    return http({
        url:"system/user/editPwd",
        method:"POST",
        data        
    })
}
export const getUploadSign = (data)=>{
    return http({
        url:"system/getUploadSign",
        method:"GET",
        data        
    })
}