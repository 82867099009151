
import * as pack from '../../api/pack.js'
import * as system from '../../api/system.js'
import { ElAlert, ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';
import roleView from '../../assets/modelView/photo.json';
export default({
    name:"Photo",
    data(){
        return {
            tableCols:[],
            queryForm:{
                pageIdx:1,
                pageSize:10,
                str:''
            },
            dataList:{
                records:[],
                total:0,
                current:1,
                pages:1
            },
            photoList:[],
            showDialog:false,
            cur:{},
            maxSize:0,
            curSize:0,
            loadingDialog:false,
            image:{},
            showImageDialog:false,
            loading:false,
            uploadSign:{
                accessId:null,
                dir:null,
                expire:null,
                host:null,
                policy:null,
                signature:null,
            }
        }
    },
    async mounted(){
        this.tableCols = roleView.tableCol;
    },
    methods:{
        currentChange(row){
            this.cur = row;

            // this.showDialog = true;
        },
        async showImage(){
            if(this.cur.id==null){
                ElMessage({
                    message:"请选择订单后操作"
                })
            }else{
                
                await this.getPhotoByOrderId();
                this.showDialog = true;
            }
        },
        async getUploadSign(){
            const {data} = await system.getUploadSign({
                orderId:this.cur.orderId
            })
            console.log(data.data);
            this.uploadSign = data.data;
        },
        async getPhotoByOrderId(){
            const {data} = await pack.getPhotoByOrderId({
                orderId:this.cur.orderId
            })
            console.log(data.data);
            this.photoList = data.data;
        },
        uploadClick(){
            this.$refs.imgBtn.click();
        },
        getUUID(){
            return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0,
                  v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
              }); 
        },
        renameFile(originalName, suffix) {
            const extensionIndex = originalName.lastIndexOf('.');
            const nameWithoutExtension = originalName.substring(0, extensionIndex);
            const extension = originalName.substring(extensionIndex);
            const newName = `${suffix}${extension}`;
            return newName;
          },
        async handleImgUpload(e){
            console.log(e.target.files.length);
            this.curSize = 0;
            this.maxSize = e.target.files.length;
            this.loadingDialog=true;
            for(;this.curSize<this.maxSize;this.curSize++){
                await this.getUploadSign();
                let params = this.createFormData(e.target.files[this.curSize]);
                const { data: res } = await system.uploadOss(this.uploadSign.host,params);
                console.log(res.data);
                console.log(123);
                let form = {
                    orderId:this.cur.orderId,
                    phoneUrl:this.uploadSign.host+params.get("key"),
                }
                const {data:r} = await pack.inserPhoto(form);
                console.log(r);
                this.photoList.push(r.data);
            }
            // let params = this.createFormData(e.target.files[0]);
            this.loadingDialog=false;
            
    //   const { data: res } = await system.uploadFile(params);
    //   let quill = this.$refs.myQuillEditor[0].getQuill();
    //   let length = quill.getSelection().index;
    //   console.log(res);
    //   quill.insertEmbed(length, "image", res.data.url);
    //   console.log(quill.container);
    //   quill.setSelection(length + 1);
        },
        createFormData(files) {
            console.log(files.name)
            var formData = new FormData();
            formData.append("key", this.uploadSign.dir+"/"+this.renameFile(files.name,this.getUUID()));
            formData.append("OSSAccessKeyId", this.uploadSign.accessId);
            formData.append("policy", this.uploadSign.policy);
            formData.append("signature", this.uploadSign.signature);
            formData.append("success_action_status","200");
            formData.append("file", files);
          
            return formData;
          },
         async  removeImage(row){
            const {data} = await pack.deletePhoto(row);

            if(data.success){
                await this.getPhotoByOrderId();
            }
        },
        showBigImage(row){
    console.log(row);            
            this.image = row;
            this.showImageDialog = true;
        }
    }
})