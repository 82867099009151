<template>
  <div id="nav">
    <div v-if="token">
      <el-container>
        <el-header class="fr fcenter">
          <div class="fr fcenter" style="gap: 6px">
            <div class="logo-title" style="color:var(--m-color)">野</div>
            <div
              class="menulist-menu"
              :class="{ animate: showMenu }"
              @click.stop="showMenu = !showMenu"
            ></div>
            <div class="logo-title" style="color:var(--m-color)">路子</div> 
          </div>
          <el-button @click="logoutClick" type="primary" link><el-icon><SwitchButton /></el-icon> 登出</el-button>
        </el-header>
        <el-container :class="{ 'side-hide': !showMenu }">
          <el-menu :collapse="!showMenu" :default-active="acMenu">
            <el-menu-item index="showHide" @click="showMenu=!showMenu">
              <el-icon><Back v-if="showMenu" /><Right v-else /></el-icon>
                <template #title>{{showMenu?'隐藏':'显示'}}</template>
            </el-menu-item>
          <div v-for="item in menu" :key="item.idx">
              <el-menu-item v-if="item.type=='item'& item.enable" :index="item.idx" @click="goPage(item)">
                <el-icon>
                  <component :is="item.icon"/>  
                </el-icon>
                <template #title>{{item.label}}</template>
              </el-menu-item>
              <el-sub-menu v-if="item.type=='sub'& item.enable" :index="item.idx">
                <template #title>
                <el-icon>
                  <component :is="item.icon"/>
                </el-icon>
                <span>{{item.label}}</span>
              </template>
              <el-menu-item v-for="subItem in item.children" :class="{'display-none' : !subItem.enable}"  :key="subItem.idx" :index="subItem.idx" @click="goPage(subItem)">
                {{ subItem.label }}
              </el-menu-item>
              </el-sub-menu>
            </div>
          </el-menu>
          <el-main>
            <router-view />
          </el-main>
        </el-container>
      </el-container>
    </div>

    <div v-else class="fc fcenter login-page">
      <div class="fr login-box">
        <el-image
          style="height: 300px"
          :src="require('@/assets/main-page-image.png')"
        ></el-image>
        <div class="login-form-area fc">
          <div class="login-form fc" v-if="loginType == '0'">
            <el-form
              ref="loginForm"
              style="width: 100%"
              :model="loginModel"
              :rules="loginRule"
              label-width="auto"
              label-position="right"
              size="large"
            >
              <el-form-item label="登录名" prop="userName">
                <el-input
                  v-model="loginModel.userName"
                  placeholder="手机号/邮箱地址"
                />
              </el-form-item>
              <el-form-item label="密码" prop="pwd">
                <el-input
                  type="password"
                  v-model="loginModel.pwd"
                  placeholder="请输入密码"
                />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="width: 100%" @click="login"
                  >登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div v-else class="login-form fc">
            <el-image
              style="
                height: 300px;
                border: 1px solid var(--m-color);
                border-radius: 5px;
              "
              :src="require('@/assets/qrcode-demo.png')"
            ></el-image>
          </div>
          <div class="fr fcenter login-type-box">
            <el-text
              @click="loginType = '0'"
              class="mx-1 pointer"
              :class="{ enableText: loginType == '0' }"
              >密码登录</el-text
            >
            <el-text class="mx-1">/</el-text>
            <el-text
              @click="loginType = '1'"
              class="mx-1 pointer"
              :class="{ enableText: loginType == '1' }"
              >扫码登录</el-text
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import * as system from "./api/system.js";
import "./style/main.css";

export default {
  
  data() {
    return {
      token: null,
      loginType: "0",
      showMenu: true,
      acMenu:'m00',
      menu:[
        {
          type:"item",idx:"m00",icon:"HomeFilled",label:"首页",url:"/",whiteList:true,enable:true
        },{
          type:"sub",idx:"m01",icon:"Grid",label:"项目相关",enable:false,children:[
            {
              type:"item",idx:"m0101",label:"门店设置",url:"/bu/shop",whiteList:false,enable:false
            },
            {
              type:"item",idx:"m0102",label:"订单管理",url:"/bu/order",whiteList:false,enable:false
            },
            {
              type:"item",idx:"m0103",label:"门店排期",url:"/bu/sc",whiteList:false,enable:false
            },{
              type:"item",idx:"m0105",label:"相册管理",url:"/bu/photo",whiteList:false,enable:false
            },
            {
              type:"item",idx:"m0104",label:"内容发布",url:"/bu/news",whiteList:false,enable:false
            },
            {
              type:"item",idx:"m0106",label:"优惠券管理",url:"/bu/coupon",whiteList:false,enable:false
            }
          ]
        }
        // ,{
        //   type:"sub",idx:"m02",icon:"location",label:"信息查询",enable:false,children:[
        //     {
        //       type:"item",idx:"m0201",label:"销售报表",url:"/info/report",whiteList:false,enable:false
        //     }
        //   ]
        // }
        ,{
          type:"sub",idx:"m03",icon:"Operation",label:"系统设置",enable:false,children:[{
              type:"item",idx:"m0901",label:"参数管理",url:"/system/param",whiteList:false,enable:false
            },{
              type:"item",idx:"m0902",label:"用户管理",url:"/system/user",whiteList:false,enable:false
            },{
              type:"item",idx:"m0903",label:"角色管理",url:"/system/role",whiteList:false,enable:false
            }
          ]
        }
      ],
      loginModel: {
        userName: null,
        pwd: null,
      },
      loginRule: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
 async mounted() {
    this.token = sessionStorage["token"];
    // console.log(this.token);
    if (this.token) {
      await this.getUserAuth();
    } else {

    }
  },
  methods: {
    goPage(item){
      this.$router.push({
        path:item.url
      });
      this.acMenu = item.idx;
    },
    async getUserAuth(){
        const{data} = await system.userAuth();
        console.log(data);
        this.checkMenu(data.data);
    },

    checkMenu(authList){
      for (var i=0;i<this.menu.length;i++){
        if(this.menu[i].type =='item'){
          if(this.menu[i].whiteList){
            this.menu[i].enable = true;
          }else{
            this.menu[i].enable = (authList.find(row=> row.id ==this.menu[i].idx )!=null)
          }
        }else{
          let hasChild = false;
          for(var j = 0;j<this.menu[i].children.length;j++){
            
            if(this.menu[i].children[j].whiteList){
            this.menu[i].children[j].enable = true;
          }else{
            this.menu[i].children[j].enable = (authList.find(row=> row.id ==this.menu[i].children[j].idx )!=null)
            hasChild = hasChild||this.menu[i].children[j].enable;
          }
          this.menu[i].enable = hasChild;
          }
        }
      }
    },
    async login() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          const { data } = await system.login(this.loginModel);
          console.log(data);
          if(data.success){
            sessionStorage.setItem("token", data.data);
            this.token = data.data;
            await this.getUserAuth(); 
          }
          
        }
      });
    },
    cleanStorage(){
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('menu');
      this.token = null;
    },
    logoutClick(){
      let that = this;
      ElMessageBox.confirm(
    '是否确认要退出?',
    'Warning',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      console.log("清理token");
      that.cleanStorage();
    })
    .catch(() => {
      
    })
    }
  },
};
</script>

<style>
html,
body,
#app {
  margin: 0;
  overflow: hidden;
}
.login-page {
  width: 100vw;
  height: 100vh;
}

.login-box {
  border: 4px solid var(--m-color);
  padding: 40px;
  border-radius: 10px;
  gap: 20px;
}

.login-form {
  height: 300px;
  width: 300px;
}
.login-type-box {
  justify-content: flex-end;
  width: 100%;
}
.el-header {
  height: 60px;
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
}
.el-aside {
  width: var(--side-width);
  /* background: var(--m-color); */
  transition: all 0.3s ease;
  border-right: 1px solid var(--border-color);
}

main {
  height: calc(100vh - 60px);
  width: 100%;
  overflow: auto;
}

.menulist-menu,
.menulist-menu:after,
.menulist-menu:before {
  width: 30px;
  height: 5px;
  cursor: pointer;
}
.menulist-menu {
  position: relative;
  /* background: #333333; */
  transition: all 0ms 300ms;
}
.menulist-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background: var(--m-color);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menulist-menu.animate {
  background: rgba(255, 255, 255, 0);
}
.menulist-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: var(--s-color);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menulist-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menulist-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.el-menu--collapse>div>.el-sub-menu>.el-sub-menu__title>span {
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
}

.el-header .el-icon{
  margin-right:5px;
}
</style>
