
import * as pack from '../../api/pack.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';
import roleView from '../../assets/modelView/sku.json';

export default({
    name:"Sku",
    data(){
        return {
            packId:null,
            tableCols:[],
            queryForm:{
                pageIdx:1,
                pageSize:10,
                str:'',
                packId:null,
            },
            dataList:{
                records:[],
                total:0,
                current:1,
                pages:1
            },
            roleFormInfo:{},
            roleInfo:{
                id:null,
                roleName:null,
            },
            roleFormRole:{

            },
            showDialog:false,
        }
    },
    async mounted(){
        this.packId = this.$route.query.packId;
        this.queryForm.packId =  this.$route.query.packId;
        this.tableCols = roleView.tableCol;
        this.roleFormInfo = roleView.editForm;
        this.roleFormRole = formToRule(roleView.editForm);
        await this.queryRole();
    },
    methods:{
        async doPackSearch(str){
            this.packQueryForm = {
                shopId:this.curShop.id,
                pageIdx:1,
                str:str
            }
            const {data} = await pack.getShopPackage( this.packQueryForm);
            this.packDataList = data.data;
        },
        async packPageChange(idx,str){
            this.packQueryForm = {
                shopId:this.curShop.id,
                pageIdx:idx,
                str:str
            }
            const {data} = await pack.getShopPackage( this.packQueryForm);
            this.packDataList = data.data;
        },
        rowChange(row){
            this.curShop = JSON.parse(JSON.stringify(row));
        },
       async queryRole(){
            const {data} = await pack.querySku(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList.records = data.data;
                this.dataList.pages = 0;
            }
       },
       addRow(){
        this.roleInfo = {};
        this.showDialog = true;
       },
       async queryPack(){
        console.log(this.curShop.id);
        if(this.curShop.id!=null){
            this.$router.push("/bu/pack?shopId="+this.curShop.id)
        }else{
            ElMessage({
                message: "请先选择需要编辑的数据后点击",
                type: "warning",
              });
        }
       
       },
       async editRow(row){
        
        this.roleInfo = JSON.parse(JSON.stringify(row));
        this.showDialog = true;
       },
       async saveForm(row){
            var  that = this;
            row.packId = this.packId;
                const {data} = await pack.editSku(row);
                if(data.success){
                    ElMessage({
                        message: '保存成功',
                        type: 'success',
                      });
                      that.queryRole();
                      that.showDialog = false;
                }
        
          
       },
       dialogClose(){
        this.showDialog = false;
    },
    backPage(){
        this.$router.back();
    },
    }
})