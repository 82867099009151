import http from "../utils/http";
export const get = (url,data)=>{
    return http({
        url:url,
        method:"GET",
        data,
    })
}
export const post = (url,data)=>{
    return http({
        url:url,
        method:"POST",
        data,
    })
}