import axios from "axios";
import http from "../utils/http";

export const roomQuery = (data) => {
    return http({
        url: "room/query",
        method: "GET",
        data,
    })
}

export const roomEdit = (data) => {
    return http({
        url: "room/edit",
        method: "POST",
        data,
    })
}

export const setCamPower = (data) => {
    return http({
        url: "room/setCamPower",
        method: "GET",
        data,
    })
}