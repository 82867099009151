<template>
    <div class="fc">
        
      <div class="page-title fr"> 
        <div @click="backPage" class="back-icon"><el-icon><Back></Back></el-icon></div>
        规格管理</div>
      <st-table style="width:100%" ref="roleTable" :cols="tableCols" 
        :records="dataList.records"
        :current="dataList.current"
        :search="false"
        :pages="dataList.pages"
        :total="dataList.total"
        @currentChange="rowChange"
        @newRow = "addRow" @editRow="editRow"
        >
        
      </st-table>
      <st-form :show="showDialog" @dialogClose="dialogClose" @saveForm ="saveForm" :formInfo="roleFormInfo" :formData="roleInfo" :rule="roleFormRole" ></st-form>
   </div>
  </template>
  <script src='./Sku.js'></script>
  