
import * as pack from '../../api/pack.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';
import roleView from '../../assets/modelView/shop.json';

import packView from '../../assets/modelView/pack.json';
export default({
    name:"Shop",
    data(){
        return {
            tableCols:[],
            queryForm:{
                pageIdx:1,
                pageSize:10,
                str:''
            },
            dataList:{
                records:[],
                total:0,
                current:1,
                pages:1
            },
            roleFormInfo:{},
            roleInfo:{
                id:null,
                roleName:null,
            },
            roleFormRole:{

            },
            showDialog:false,
            showPackDialog:false,
            curShop:{},
            packQueryForm:{
                str:'',
                shopId:''
            },
            packTableCols:[],
            packDataList:{
                records:[],
                total:0,
                current:1,
                pages:1
            },
            packRoleFormInfo:{},
            packInfo:{
                id:null,
            },
            packFormRole:{

            },
        }
    },
    async mounted(){
        this.tableCols = roleView.tableCol;
        this.roleFormInfo = roleView.editForm;
        this.roleFormRole = formToRule(roleView.editForm);
        this.packTableCols = packView.tableCol;
        this.packFormInfo = packView.editForm;
        this.packFormRole = formToRule(packView.editForm);
        await this.queryRole();
    },
    methods:{
        async doPackSearch(str){
            this.packQueryForm = {
                shopId:this.curShop.id,
                pageIdx:1,
                str:str
            }
            const {data} = await pack.getShopPackage( this.packQueryForm);
            this.packDataList = data.data;
        },
        async packPageChange(idx,str){
            this.packQueryForm = {
                shopId:this.curShop.id,
                pageIdx:idx,
                str:str
            }
            const {data} = await pack.getShopPackage( this.packQueryForm);
            this.packDataList = data.data;
        },
        rowChange(row){
            this.curShop = JSON.parse(JSON.stringify(row));
        },
       async queryRole(){
            const {data} = await pack.shopList(this.queryForm);
            console.log(data);
            if(data.success){
                this.dataList = data.data;
            }
       },
       addRow(){
        if(this.dataList.records.size()>0){
            ElMessage({
                message: "暂时只支持一家门店",
                type: "warning",
              });
        }else{
            this.roleInfo = {};
            this.showDialog = true;
        }
       
       },
       async queryRoom(){
        if(this.curShop.id!=null){
            this.$router.push("/bu/roomAdmin?shopId="+this.curShop.id)
        }else{
            ElMessage({
                message: "请先选择需要编辑的数据后点击",
                type: "warning",
              });
        }
       },

       async queryPack(){
        console.log(this.curShop.id);
        if(this.curShop.id!=null){
            this.$router.push("/bu/pack?shopId="+this.curShop.id)
        }else{
            ElMessage({
                message: "请先选择需要编辑的数据后点击",
                type: "warning",
              });
        }
       
       },
       async editRow(row){
        const {data} = await pack.getShop({
            id:row.id
        });
        console.log(data);
        this.roleInfo = data.data;
        this.showDialog = true;
       },
       async saveForm(row){
            var  that = this;
                const {data} = await pack.editShop(row);
                if(data.success){
                    ElMessage({
                        message: '保存成功',
                        type: 'success',
                      });
                      that.$refs.roleTable.doSearch();
                      that.showDialog = false;
                }
        
          
       },
       dialogClose(){
        this.showDialog = false;
    },
    }
})