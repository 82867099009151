
 export const formToRule=(json)=>{
    var _rule={};
    for(var i = 0;i<json.length;i++){
        let _subRules = [];
        if(json[i]["requied"]){
            _subRules.push({ required: true, message: "请输入", trigger: "blur" });
        }
        if(json[i]["maxLength"]){
            _subRules.push({ max: json[i]["maxLength"], message: '最大长度是'+json[i]["maxLength"], trigger: 'blur' },)
        }
        _rule[json[i].prop] = _subRules;
    }

    return _rule;
 }