
import * as pack from '../../api/pack.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { formToRule } from '../../utils/FormInfoUtils.js';
import roleView from '../../assets/modelView/Sche.json';
import autoView from  '../../assets/modelView/autoSchu.json';

export default({
    name:"Sche",
    data(){
        return {
            packId:null,
            tableCols:[],
            queryForm:{
                pageIdx:1,
                pageSize:10,
                str:'',
                packId:null,
            },
            dataList:[],
            roleFormInfo:{},
            roleInfo:{
                id:null,
                roleName:null,
            },
            roleFormRole:{

            },

            autoFormInfo:{},
            autoInfo:{
                id:null,
                roleName:null,
            },
            autoFormRole:{

            },
            showDialog:false,
            showCreateDialog:false,

            scheFormInfo:{},
            scheInfo:{},
            scheFormRole:{},
            scheDialog:false

        }
    },
    async mounted(){
        this.packId = this.$route.query.packId;
        this.queryForm.packId =  this.$route.query.packId;
        this.roleFormInfo = roleView.editForm;
        this.roleFormRole = formToRule(roleView.editForm);
        this.autoFormInfo = autoView.editForm;
        this.autoFormRole =  formToRule(autoView.editForm);

        this.scheFormInfo = autoView.scheForm;
        this.scheFormRole =  formToRule(autoView.scheForm);
        await this.queryRole();
    },
    methods:{
        async doPackSearch(str){
            this.packQueryForm = {
                shopId:this.curShop.id,
                pageIdx:1,
                str:str
            }
            const {data} = await pack.getShopPackage( this.packQueryForm);
            this.packDataList = data.data;
        },
        async packPageChange(idx,str){
            this.packQueryForm = {
                shopId:this.curShop.id,
                pageIdx:idx,
                str:str
            }
            const {data} = await pack.getShopPackage( this.packQueryForm);
            this.packDataList = data.data;
        },
        rowChange(row){
            this.curShop = JSON.parse(JSON.stringify(row));
        },
       async queryRole(){
            const {data} = await pack.packSche({
                packId:this.packId
            });
            console.log(data);
            
            if(data.success){
                this.dataList = data.data;
            }
       },
       addRow(){
        this.roleInfo = {};
        this.showDialog = true;
       },
       async queryPack(){
        console.log(this.curShop.id);
        if(this.curShop.id!=null){
            this.$router.push("/bu/pack?shopId="+this.curShop.id)
        }else{
            ElMessage({
                message: "请先选择需要编辑的数据后点击",
                type: "warning",
              });
        }
       
       },
       async editRow(row){
        
        this.roleInfo = JSON.parse(JSON.stringify(row));
        this.showDialog = true;
       },
       async saveForm(row){
            var  that = this;
            row.packId = this.packId;
                const {data} = await pack.editSku(row);
                if(data.success){
                    ElMessage({
                        message: '保存成功',
                        type: 'success',
                      });
                      that.queryRole();
                      that.showDialog = false;
                }
        
          
       },
       dialogClose(){
        this.showDialog = false;
    }, AutodialogClose(){
        this.showCreateDialog = false;
        this.autoInfo = {};
    },
    backPage(){
        this.$router.back();
    },
    autoCreate(){
        this.autoInfo = {};
        this.showCreateDialog = true;
       
    },
    async autoSaveForm(){
        this.autoInfo.packId = this.packId;
        console.log(this.autoInfo);
        const {data} = await pack.createSche(this.autoInfo);
        if(data.success){
            await this.queryRole();
        }
    },
    async deleteSche(row){
        const {data} = await pack.deleteSche(row);
        if(data.success){
            await this.queryRole();
        }
    },
    async deleteDay(row){
        const {data} = await pack.deleteDay({
            packId:this.packId,
            scheDate:row.scheDate
        })
        if(data.success){
            await this.queryRole();
        }
    },
    scheDialogClose(){
        this.scheDialog = false;
    },
    editSche(row){
        this.scheInfo = JSON.parse(JSON.stringify(row));
        this.scheDialog = true;
    },
    addSche(){
        this.scheInfo= {};
        this.scheDialog = true;
    },
    async scheSaveForm(){
        this.scheInfo.packId = this.packId;
        console.log(this.scheInfo);
        const {data} = await pack.editSche(this.scheInfo);
        if(data.success){
            this.scheInfo = {};
            this.scheDialog = false;
            this.queryRole();
        }
    }
    }
})