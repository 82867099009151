import axios from "axios";
import http from "../utils/http";


// export const editNews = (data) => {
//     return http({
//         url: "news/editNews",
//         method: "POST",
//         data,
//     })
// }
export const shopList = (data) => {
    return http({
        url: "pack/shopList",
        method: "GET",
        data,
    })
}
export const getShop  = (data) => {
    return http({
        url: "pack/getShop",
        method: "GET",
        data,
    })
}

export const editShop= (data) => {
    return http({
        url: "pack/editShop",
        method: "POST",
        data,
    })
}
export const getShopPackage= (data) => {
    return http({
        url: "pack/queryPack",
        method: "GET",
        data,
    })
}

//
export const editShopPack= (data) => {
    return http({
        url: "pack/editShopPack",
        method: "POST",
        data,
    })
}

export const  querySku= (data) => {
    return http({
        url: "pack/querySky",
        method: "GET",
        data,
    })
}
export const  editSku= (data) => {
    return http({
        url: "pack/editSku",
        method: "POST",
        data,
    })
}
export const packSche = (data) => {
    return http({
        url: "pack/packSche",
        method: "GET",
        data,
    })
}

export const createSche  = (data) => {
    return http({
        url: "pack/createSche",
        method: "POST",
        data,
    })
}

//deleteSche

export const deleteSche  = (data) => {
    return http({
        url: "pack/deleteSche",
        method: "POST",
        data,
    })
}
//editSche
export const editSche  = (data) => {
    return http({
        url: "pack/editSche",
        method: "POST",
        data,
    })
}
export const deleteDay  = (data) => {
    return http({
        url: "pack/deleteDay",
        method: "POST",
        data,
    })
}

export const shopDateSche = (data)=>{
    return http({
        url: "pack/shopDateSche",
        method: "GET",
        data,
    })
}

export const setAreaState  = (data) => {
    return http({
        url: "pack/setAreaState",
        method: "POST",
        data,
    })
}

export const inSche  = (data) => {
    return http({
        url: "pack/inSche",
        method: "POST",
        data,
    })
}

export const queryOrders = (data) => {
    return http({
        url: "pack/queryOrders",
        method: "GET",
        data,
    })
}

export const getPhotoByOrderId = (data)=>{
    return http({
        url: "pack/getPhotoByOrderId",
        method: "GET",
        data,
    })
}

export const inserPhoto = (data)=>{
    return http({
        url: "pack/inserPhoto",
        method: "POST",
        data,
    })
}

export const deletePhoto= (data)=>{
    return http({
        url: "pack/deletePhoto",
        method: "POST",
        data,
    })
}

export const editOrderRemark= (data)=>{
    return http({
        url: "pack/editOrderRemark",
        method: "POST",
        data,
    })
}

//
export const cancelOrder= (data)=>{
    return http({
        url: "pack/cancelOrder",
        method: "POST",
        data,
    })
}

export const getRefund = (data)=>{
    return http({
        url: "pack/getRefund",
        method: "GET",
        data,
    })
}

export const createRefund= (data)=>{
    return http({
        url: "pack/createRefund",
        method: "POST",
        data,
    })
}